:root {
	--module-solutions-gray-arrow-size: 1.1875rem;
	--module-solutions-chevron-size: 1rem;
	--module-solutions-transition-speed: 200ms;
}

#solutions-module {
	&.collection {
		.node {
			display: flex;
			margin-bottom: 0rem;
			padding: 2.75rem 0;

			.solution-node-image {
				flex: 0 0 auto;
				height: 145px;
				margin-right: 2rem;
				margin-top: 0px;
				width: 145px;

				img {
					.module-img {
						margin-right: 2rem;
						width: 100%;
					}
				}
			}

			.solution-node-summary {
				h3 {
					margin-top: 0;
					padding-top: 0;
				}

				.solution-node-summary-more-details {
					align-items: center;
					border-bottom: 0;
					display: flex;
					font-size: 0.9rem;
					font-weight: 700;
					letter-spacing: 0.05px;
					line-height: 0.9rem;
					padding-right: 0rem;
					text-decoration: none;
					text-transform: uppercase;

					svg {
						height: var( --module-solutions-gray-arrow-size );
						margin-right: 0.75rem;
						width: var( --module-solutions-gray-arrow-size );
					}
				}
			}
		}
	}

	&.node {
		.solution-overview {
			display: flex;
			flex-direction: row;
			padding-left: 0rem;
			padding-right: 0rem;
			padding-top: 2rem;

			.solution-image {
				align-items: center;
				display: flex;
				height: 200px;
				margin-bottom: auto;
				margin-right: 3rem;
				margin-top: auto;
				width: 175px;
			}

			.solution-intro {
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 85%;

				p {
					font-size: 137.5%;
					line-height: 150%;
				}
			}
		}

		.solution-cards {
			align-items: flex-start;
			display: flex;
			justify-content: space-between;
			padding: 2rem 0;
			width: 100%;

			.solution-cards-column {
				margin-right: 0;

				&.content {
					display: block;
					padding-right: 1.5rem;
					width: 40%;

					.solution-cards-container {
						margin-top: 2rem;

						.expandable {
							.solution-card-body {
								max-height: 0;
								transition: 0.5s max-height;
								overflow: hidden;
							}

							&.active .solution-card-body {
								max-height: unset;
							}
						}
					}
				}

				&.image {
					align-items: flex-start;
					display: flex;
					justify-content: center;
					margin-left: auto;
					padding-right: 0;
					position: relative;
					width: 60%;
					box-shadow: 2px 5px 10px -5px;
					border-radius: 10px;
					margin-top: 20px;
					transition: height 1s;

					.solution-card-image {
						position: absolute;
						left: 0%;
						top: 0%;
						right: 0%;
						bottom: 0%;
						z-index: auto;
						width: 100%;
						height: 100%;
						opacity: 0;
						transition: opacity 1s;
						background-image: url("/core/modules/solutions/assets/images/template.png");
						background-position: 0% 0%;
						background-repeat: no-repeat;
						background-size: 100% auto;
						padding-top: 7.65%;
						margin-top: -2.43%;

						&.fade-in {
							opacity: 1;
						}

						img {
							border-radius: 0px 0px 10px 10px;
						}
					}
				}
			}

			.solution-card-header {
				cursor: pointer;
				margin-bottom: 1rem;

				svg {
					height: 11px;
					width: 11px;
				}

				.solution-card-header-title {
					padding-left: 0.5rem;
				}
			}

			.solution-card-body-description {
				background-color: rgba(230, 235, 245, 0.35);
				border-radius: 8px;
				border-top: 1px none #000000;
				font-size: 1rem;
				line-height: 2rem;
				margin-bottom: 1rem;
				margin-left: 1.65rem;
				margin-top: 0.25rem;

				div {
					padding: 0.5rem 1rem;
				}

				p:last-of-type {
					margin-bottom: 0;
				}
			}

			.solution-card-footer {
				img {
					display: none;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#solutions-module {
		&.node {
			.solution-overview {
				flex-direction: column;
			}

			.solution-cards {
				padding-bottom: 0;

				.solution-cards-column {
					&.content {
						width: 100%;
					}

					&.image {
						display: none;
					}
				}

				.solution-card-footer {
					text-align: center;
					margin-left: 1.65rem;

					img {
						display: initial;
						height: auto;
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 479px) {
	#solutions-module {
		&.collection {
			.node {
				align-items: flex-start;
				flex-direction: column;
				padding: 2rem 0;

				.solution-node-image {
					display: block;
					margin-bottom: 1rem;
					margin-left: auto;
					margin-right: auto;
				}

				.solution-node-summary {
					align-self: flex-start;
					text-align: center;

					.solution-node-summary-content {
						align-items: flex-start;
						display: flex;
						flex-direction: column;
						text-align: left;
					}
				}
			}
		}
	}
}
